export const tokens = {
  nav: {
    content: "nav.content",
    documents: "nav.documents",
    tests: "nav.tests",
    quiz: "nav.quiz",
    courses: "nav.courses",
    category: "nav.category",
  },
  common: {
    basePage: "common.basePage",
    list: "common.list",
    create: "common.create",
    edit: "common.edit",
    cancel: "common.cancel",
    active: "common.active",
    blocked: "common.blocked",
    archive: "common.archive",
    details: "common.details",
    hide: "common.hide",
    publish: "common.publish",
    delete: "common.delete",
    date: "common.date",
    noData: "common.noData",
    restore: "common.restore",
    copy: "common.copy",
    save: "common.save",
    upload: "common.upload",
    deleteAll: "common.deleteAll",
    roles: "common.roles",
    activeTab: 'common.activeTab',
    archiveTab: 'common.archiveTab',
    fillAllFields: 'common.fillAllFields',
    watch: 'common.watch',
    tooMuchSymbols: "common.tooMuchSymbols",
    editAccess: "common.editAccess",
    editAssign: "common.editAssign",
    reAssign: "common.reAssign",
    assign: "common.assign",
    access: "common.access",
    assignDate: "common.assignDate",
    actions: "common.actions",
    emptyState: "common.emptyState",
    emptyStateAccess: "common.emptyStateAccess",
    emptyStateAssign: "common.emptyStateAssign",
    add: "common.add"
  },
  auth: {
    phoneNumber: "auth.phoneNumber",
    password: "auth.password",
    enter: "auth.enter",
    forgetPassword: "auth.forgetPassword",
    recoverPassword: "auth.recoverPassword",
    ok: "auth.ok",
    show: "auth.show",
    hide: "auth.hide",
    newPassword: "auth.newPassword",
    confirmPassword: "auth.confirmPassword",
    saveNewPassword: "auth.saveNewPassword",
    messages: {
      phoneValidation: "auth.messages.phoneValidation",
      passwordValidation: "auth.messages.passwordValidation",
      forgetPassword: "auth.messages.forgetPassword",
      loginError: "auth.messages.loginValidation",
      wrongData: "auth.messages.wrongData",
      smsSent: "auth.messages.smsSent",
      sendAgain: "auth.messages.sendAgain",
      userNotFound: "auth.messages.userNotFound",
      threeAttempts: "auth.messages.threeAttempts",
      phoneIsRequired: "auth.messages.phoneIsRequired",
      passIsRequired: "auth.messages.passIsRequired",
      passIsNotMatch: "auth.messages.passIsNotMatch",
      passChangedSuccessfully: "auth.messages.passChangedSuccessfully",
    }
  },
  tables: {},
  forms: {},
  users: {
    users: "users.users",
    passwordChanged: "users.passwordChanged",
    passwordChangedError: "users.passwordChangedError",
    minPassLength: "users.minPassLength",
    usersList: "users.usersList",
    userDetail: "users.userDetail",
    settings: "users.settings",
    city: 'users.city',
    position: 'users.position',
    birthday: 'users.birthday',
    daysInCompany: 'users.daysInCompany',
    phone: 'users.phone',
    status: 'users.status',
    email: 'users.email',
    handleUsers: "users.handleUsers",
    serachUser: "users.searchUser",
    structure: "users.structure",
    companyAdministration: "users.companyAdministration",
    editUser: "users.editUser",
    role: "users.role",
    userRoles: "users.userRoles",
    permissions: "users.permissions",
    all: "users.all",
    interns: "users.interns",
    pizzaMade: "users.pizzaMade",
    name: "users.name",
    secondName: "users.secondName",
    emptyList: "users.emptyList",
    emptyListDesc: "users.emptyListDesc",
    ordersHistory: {
      ordersHistory: "users.ordersHistory.ordersHistory",
      new: "users.ordersHistory.new",
      processed: "users.ordersHistory.processed",
      searchOrderByNumber: "users.ordersHistory.searchOrderByNumber",
      points: "users.ordersHistory.points",
      units: "users.ordersHistory.units",
      closeOrder: "users.ordersHistory.closeOrder",
      active: "users.ordersHistory.active",
      hidden: "users.ordersHistory.hidden",
      documentCategory: "users.ordersHistory.documentCategory",
      knowledgeCategory: "users.ordersHistory.knowledgeCategory",
      emptyListTitle: 'users.ordersHistory.emptyListTitle',
      emptyListDescription: 'users.ordersHistory.emptyListDescription'
    },
    studyHistory: {
      studyHistory: "users.studyHistory.studyHistory",
      rating: "users.studyHistory.rating",
      exercise: "users.studyHistory.exercise",
      type: "users.studyHistory.type",
      assignmentDate: "users.studyHistory.assignmentDate",
      completionDate: "users.studyHistory.completionDate",
      completionTermin: "users.studyHistory.completionTermin",
      result: "users.studyHistory.result",
      emptyList: "users.studyHistory.emptyList",
      emptyListDesc: "users.studyHistory.emptyListDesc",
      searchByTaskName: "users.studyHistory.searchByTaskName",
      completed: "users.studyHistory.completed",
      inProgress: "users.studyHistory.inProgress",
      overdue: "users.studyHistory.overdue",
      familiarize: "users.studyHistory.familiarize",
      points: "users.studyHistory.points",
      aboutBadge: "users.studyHistory.aboutBadge",
      assign: "users.studyHistory.assign",
      failed: "users.studyHistory.failed",
    }
  },
  category: {
    documentCategory: "category.documentCategory",
    editCategory: "category.editCategory",
    searchByCategoryName: "category.searchByCategoryName",
    createCategory: "category.createCategory",
    categoryTitle: "category.categoryTitle",
    parentCategory: "category.parentCategory",
    makeCategoryHidden: "category.makeCategoryHidden",
    showCategory: "category.showCategory",
    uploadImage: "category.uploadImage",
    emptyListTitle: "category.emptyListTitle",
    emptyListDesc: "category.emptyListDesc",
    maxSize500: "category.maxSize500",
    messages: {
      categoryUpdated: "category.messages.categoryUpdated",
      categoryCreated: "category.messages.categoryCreated",
    }
  },
  documents: {
    documents: "documents.documents",
    createDocument: "documents.createDocument",
    editDocument: "documents.editDocument",
    listDocuments: "documents.listDocuments",
    searchByDocumentName: "documents.searchByDocumentName",
    active: "documents.active",
    all: "documents.all",
    page: "documents.page",
    adaptivePage: "documents.adaptivePage",
    file: "documents.file",
    video: "documents.video",
    link: "documents.link",
    htmlPage: "documents.htmlPage",
    gallery: "documents.gallery",
    available: "documents.available",
    hidden: "documents.hidden",
    publish: "documents.publish",
    notPublish: "documents.notPublish",
    applyFilters: "documents.applyFilters",
    cancelFilters: "documents.cancelFilters",
    category: "documents.category",
    type: "documents.type",
    status: "documents.status",
    initiator: "documents.initiator",
    uploadReport: "documents.uploadReport",
    title: "documents.title",
    definePermission: "documents.definePermission",
    assign: "documents.assign",
    inform: "documents.inform",
    content: "documents.content",
    addFile: "documents.addFile",
    emptyListTitle: "documents.emptyListTitle",
    emptyListDesc: "documents.emptyListDesc",
    textVideo: "documents.textVideo",
    contentPage: "documents.contentPage",
    uploadFromComputer: "documents.uploadFromComputer",
    imagesDNDTitleP1: "documents.imagesDNDTitleP1",
    imagesDNDTitleP2: "documents.imagesDNDTitleP2",
    imagesDNDSubtitle: "documents.imagesDNDSubtitle",
    image: "documents.image",
    maxSizeFile: "documents.maxSizeFile",
    maxSizeFileVideo: "documents.maxSizeFileVideo",
    await: "documents.await",
    maxSizeText: "documents.maxSizeText",
    accessToUser: "documents.accessToUser",
    accessToDepartment: "documents.accessToDepartment",
    accessToPosition: "documents.accessToPosition",
    assignToUser: "documents.assignToUser",
    assignToDepartment: "documents.assignToDepartment",
    assignToPosition: "documents.assignToPosition",
    messages: {
      documentUpdated: "documents.messages.documentUpdated",
      documentRemoved: "documents.messages.documentRemoved",
      documentCreated: "documents.messages.documentCreated",
      tooManySymbols: "documents.messages.tooManySymbols"
    }
  },
  adverts: {
    adverts: "adverts.adverts",
    createAdvert: "adverts.createAdvert",
    editAdvert: "adverts.editAdvert",
    advertList: "adverts.advertList",
    news: "adverts.news",
    newsCeo: "adverts.newsCeo",
    promo: "adverts.promo",
    event: "adverts.event",
    type: "adverts.type",
    theme: "adverts.theme",
    initiator: "adverts.initiator",
    permission: "adverts.permission",
    familiarization: "adverts.familiarization",
    cover: "adverts.cover",
    startEvent: "adverts.startEvent",
    endEvent: "adverts.endEvent",
    fileTooBig: "adverts.fileTooBig",
    fillAllFields: "adverts.fillAllFields",
    emptyListTitle: "adverts.emptyListTitle",
    emptyListDesc: "adverts.emptyListDesc",
    forKnowledgeBase: "adverts.forKnowledgeBase",
    messages: {
      advertUpdated: "adverts.messages.advertUpdated",
      advertCreated: "adverts.messages.advertCreated",
      advertRemoved: "adverts.messages.advertRemoved",
      searchByAdvertName: "adverts.messages.searchByAdvertName",
    }
  },
  test: {
    testList: "test.testList",
    searchTestByTitle: "test.searchTestByTitle",
    emptyList: "test.emptyList",
    emttyStateDesc: "test.emttyStateDesc",
    createTest: "test.createTest",
    editTest: "test.editTest",
    theme: "test.theme",
    badge: "test.badge",
    initiator: "test.initiator",
    accessToTest: "test.accessToTest",
    deadline: "test.deadline",
    attemptsAmount: "test.attemptsAmount",
    minPoints: "test.minPoints",
    points: "test.points",
    shortlyAboutTest: "test.shortlyAboutTest",
    addQuestion: "test.addQuestion",
    createQuestion: "test.createQuestion",
    editQuestion: "test.editQuestion",
    type: "test.type",
    title: "test.title",
    allPoints: "test.allPoints",
    addReply: "test.addReply",
    uploadFile: "test.uploadFile",
    testInitiator: "test.testInitiator",
    questions: "test.questions",
    fileTooBig: "test.fileTooBig",
    needTwoQuestions: "test.needTwoQuestions",
    typeSingle: "test.typeSingle",
    typeMultiple: "test.typeMultiple",
    typeOrder: "test.typeOrder",
    typeFree: "test.typeFree",
    typeScale: "test.typeScale",
    chooseCorrectReply: "test.chooseCorrectReply",
    duration: "test.duration",
    message: {
      testUpdated: "test.message.testUpdated",
      testCreated: "test.message.testCreated",
      testRemoved: "test.message.testRemoved",
      questionRemoved: "test.message.questionRemoved",
      questionUpdated: "test.message.questionUpdated",
      questionAdded: "test.message.questionAdded",
      helperTextForAddingQuestion: "test.message.helperTextForAddingQuestion",
      needAddOneQuestion: "test.message.needAddOneQuestion",
      needQuestionForReplies: "test.message.needQuestionForReplies"
    }
  },
  backup: {
    backup: "backup.backup",
    backupList: "backup.backupList",
    createBackUp: "backup.createBackUp",
    restoreBackUp: "backup.restoreBackUp",
    enterKey: "backup.enterKey",
    backupKey: "backup.backupKey",
    backupId: "backup.backupId",
    date: "backup.date",
    messages: {
      backupCreated: "backup.messages.backupCreated",
      backupRestored: "backup.messages.backupRestored",
    }
  },
  badge: {
    navBar: {
      title: "badge.navBar.title",
      list: "badge.navBar.list",
      create: "badge.navBar.create"
    },
    messages: {
      delete: "badge.messages.delete"
    },
    tooltip: {
      delete: "badge.tooltip.delete",
      edit: "badge.tooltip.edit"
    },
    tabs: {
      active: "badge.tabs.active",
      archive: "badge.tabs.archive"
    },
    badges: {
      title: 'badge.badges.title',
      buttonCreate: 'badge.badges.buttonCreate'
    },
  },
  roles: {
    title: {
      rolesList: 'roles.title.rolesList',
      createRole: 'roles.title.createRole',
      editRole: 'roles.title.editRole'
    },
    messages: {
      roleUpdated: "roles.messages.roleUpdated",
      roleCreated: "roles.messages.roleCreated",
      roleRemoved: "roles.messages.roleRemoved"
    },
    emptyListTitle: 'roles.emptyListTitle',
    emptyListDescription: 'roles.emptyListDescription',
    name: 'roles.name',
    permissions: 'roles.permissions'
  },
  appPage: {
    navBar: {
      title: "appPage.navBar.title",
      list: "appPage.navBar.list",
      create: "appPage.navBar.create"
    },
    title: {
      list: 'appPage.title.list',
      create: 'appPage.title.create',
      edit: 'appPage.title.edit'
    },
    messages: {
      updated: "appPage.messages.updated",
      created: "appPage.messages.created",
      removed: "appPage.messages.removed",
      maxDescriptionLength: "appPage.messages.maxDescriptionLength"
    },
    form: {
      name: 'appPage.form.name',
      description: 'appPage.form.description',
      images: 'appPage.form.images',
    },
    show: 'appPage.show',
    emptyListTitle: 'appPage.emptyListTitle',
    emptyListDescription: 'appPage.emptyListDescription'
  },
  notification: {
    notification: "notification.notification",
    notificationList: "notification.notificationList",
    emptyListTitle: "notification.emptyListTitle",
    emptyListDesc: "notification.emptyListDesc",
    readyForSending: "notification.readyForSending",
    sended: "notification.sended",
    archive: "notification.archive",
    timeIsSet: 'notification.timeIsSet',
    invalidLink: "notification.invalidLink",
    link: "notification.link",
    image: "notification.image",
    fillFields: "notification.fillFields",
    pushCreated: "notification.pushCreated",
    createPush: "notification.createPush",
    all: "notification.all",
    user: "notification.user",
    region: "notification.region",
    status: "notification.status",
    group: "notification.group",
    audience: "notification.audience",
    country: "notification.country",
    userId: "notification.userId",
    searchUsers: "notification.searchUsers",
    title: "notification.title",
    enterTitle: "notification.enterTitle",
    text: "notification.text",
    enterText: "notification.enterText",
    imageUrl: "notification.imageUrl",
    linkTo: "notification.linkTo",
    imageDetail: "notification.imageDetail",
    putOff: "notification.putOff",
    timeSending: "notification.timeSending",
    chooseTime: "notification.chooseTime",
    addImage: "notification.addImage",
    removeImage: "notification.removeImage",
    updatePush: 'notification.updatePush',
    pushUpdated: "notification.pushUpdated",
    pushDeleted: "notification.pushDeleted",
    pushIsSend: "notification.pushIsSend",
    pushDetails: "notification.pushDetails",
    ready: "notification.ready",
    completed: "notification.completed",
    error: "notification.error",
    users: "notification.users",
    send: "notification.send",
    preview: "notification.preview",
    role: "notification.role",
    maxSizeFile: "notification.maxSizeFile",
    imageUrlNotCorrect: "notification.imageUrlNotCorrect",
    linkNotCorrect: "notification.linkNotCorrect",
    navBar: {
      list: "notification.navBar.list"
    },
    message: {
      notificationRemoved: "notification.message.notificationRemoved"
    },
  },
  shop: {
    navBar: {
      title: "shop.navBar.title",
      list: "shop.navBar.list",
      ordersList: "shop.navBar.ordersList"
    },
    title: {
      create: 'shop.title.create',
      edit: 'shop.title.edit'
    },
    messages: {
      updated: "shop.messages.updated",
      created: "shop.messages.created",
      removed: "shop.messages.removed",
      maxSizeFile: 'shop.messages.maxSizeFile',
      maxDescriptionLength: 'shop.messages.maxDescriptionLength',
      maxNameLength: 'shop.messages.maxNameLength',
    },
    form: {
      name: 'shop.form.name',
      description: 'shop.form.description',
      images: 'shop.form.images',
      price: 'shop.form.price',
      amount: 'shop.form.amount',
      publish: 'shop.form.publish',
      imagesDNDSubtitle: "shop.form.imagesDNDSubtitle",
    },
    amount: 'shop.amount',
    points: 'shop.points',
    show: 'shop.show',
    emptyListTitle: 'shop.emptyListTitle',
    emptyListDescription: 'shop.emptyListDescription'
  },
  order: {
    title: {
      ordersHistory: 'order.title.ordersHistory',
      searchOrder: 'order.title.searchOrder',
      new: 'order.title.new',
      processed: 'order.title.processed',
      closeOrder: 'order.title.closeOrder',
      orderClosed: 'order.title.orderClosed'
    },
    messages: {
      updated: 'order.messages.updated',
    },
    emptyListTitle: 'order.emptyListTitle',
    emptyListDescription: 'order.emptyListDescription'
  },
  quiz: {
    emptyListTitle: "quiz.emptyListTitle",
    emptyListDesc: "quiz.emptyListDesc",
    quizList: "quiz.quizList",
    searchByName: "quiz.searchByName",
    createQuiz: "quiz.createQuiz",
    editQuiz: "quiz.editQuiz",
    assign: "quiz.assign",
    addQuestion: "quiz.addQuestion",
    publish: "quiz.publish",
    inform: "quiz.inform",
    nameLessQuiz: "quiz.nameLessQuiz",
    theme: "quiz.theme",
    initiator: "quiz.initiator",
    accessToQuiz: "quiz.accessToQuiz",
    shortlyAboutQuiz: "quiz.shortlyAboutQuiz",
    message: {
      quizUpdated: "quiz.message.quizUpdated",
      quizCreated: "quiz.message.quizCreated",
      quizRemoved: "quiz.message.quizRemoved",
      questionAdded: "quiz.message.questionAdded",
      questionUpdated: "quiz.message.questionUpdated",
    }
  },
  feedback: {
    inWork: "feedback.inWork",
    takeToDevelop: "feedback.takeToDevelop",
    refusal: "feedback.refusal",
    duplicate: "feedback.duplicate",
    pause: "feedback.pause",
    notEnoughInformation: "feedback.notEnoughInformation",
    embodied: "feedback.embodied",
    generalQuestionList: "feedback.generalQuestionList",
    searchByName: "feedback.searchByName",
    chooseDecision: "feedback.chooseDecision",
    assignResponsible: "feedback.assignResponsible",
    emptyList: "feedback.emptyList",
    ideaList: "feedback.ideaList",
    anonymousComplaint: "feedback.anonymousComplaint",
    anonymousComplaintList: "feedback.anonymousComplaintList",
    commonQuestion: "feedback.commonQuestion",
    feedback: "feedback.feedback",
    isAnIdea: "feedback.isAnIdea",
    ideaDesc: "feedback.ideaDesc",
    ideaImpact: "feedback.ideaImpact",
    examples: "feedback.examples",
    takePart: "feedback.takePart",
    generalQuestion: {
      emptyListDesc: "feedback.generalQuestion.emptyListDesc"
    },
    isIdea: {
      emptyListDesc: "feedback.isIdea.emptyListDesc"
    },
    complaint: {
      emptyListDesc: "feedback.complaint.emptyListDesc"
    },
    message: {
      questionUpdated: "feedback.message.questionUpdated",
      ideaUpdated: "feedback.message.ideaUpdated",
      complaintUpdated: "feedback.message.complaintUpdated",
    }
  },
  course: {
    course: "course.course",
    createCourse: "course.createCourse",
    editCourse: "course.editCourse",
    initiator: "course.initiator",
    access: "course.access",
    shortlyAboutCourse: "course.shortlyAboutCourse",
    nextDay: "course.nextDay",
    addTask: "course.addTask",
    addTaskHelper: "course.addTaskHelper",
    emptyListTitle: "course.emptyListTitle",
    emptyListDesc: "course.emptyListDesc",
    courseList: "course.courseList",
    searchByName: "course.searchByName",
    document: "course.document",
    test: "course.test",
    quiz: "course.quiz",
    createTask: "course.createTask",
    editTask: "course.editTask",
    day: "course.day",
    taskType: "course.taskType",
    searchTaskByType: "course.searchTaskByType",
    numDay: "course.numDay",
    nextDayAvailable: "course.nextDayAvailable",
    afterEndPrevious: "course.afterEndPrevious",
    andStartNextDay: "course.andStartNextDay",
    message: {
      courseUpdated: "course.message.courseUpdated",
      courseCreated: "course.message.courseCreated",
      courseRemoved: "course.message.courseRemoved",
      taskUpdated: "course.message.taskUpdated",
      taskCreated: "course.message.taskCreated",
      taskRemoved: "course.message.taskRemoved",
    }
  },
  analytics: {
    analytics: "analytics.analytics",
    generalAnalytics: "analytics.generalAnalytics",
    all: "analytics.all",
    active: "analytics.active",
    working: "analytics.working",
    userActivity: "analytics.userActivity",
    inRealTime: "analytics.inRealTime",
    updateInfo: "analytics.updateInfo",
    complete: "analytics.complete",
    failed: "analytics.failed",
    analyticsPassCourse: "analytics.analyticsPassCourse",
    analyticsCourse: "analytics.analyticsCourse",
    analyticsKnowledge: "analytics.analyticsKnowledge",
    documents: "analytics.documents",
    news: "analytics.news",
    promotion: "analytics.promotion",
    events: "analytics.events",
    usersInBd: "analytics.usersInBd",
    serachRequests: "analytics.serachRequests", 
    analyticsUser: "analytics.analyticsUser",
    analyticsResult: "analytics.analyticsResult",
    test: "analytics.test",
    quiz: "analytics.quiz",
    course: "analytics.course",
    analyticsContent: "analytics.analyticsContent",
    analyticsAdvert: "analytics.analyticsAdvert",
    analyticsProcess: "analytics.analyticsProcess",
    analyticsBadge: "analytics.analyticsBadge",
    timeContent: "analytics.timeContent",
    timeAdvert: "analytics.timeAdvert",
    myProcess: "analytics.myProcess",
    sections: "analytics.sections",
    feedback: "analytics.feedback",
    personalReserve: "analytics.personalReserve",
    checkin: "analytics.checkin",
    result: "analytics.result",
    productRating: "analytics.productRating",
    allProducts: "analytics.allProducts",
    name: "analytics.name",
    price: "analytics.price",
    amount: "analytics.amount",
  },
  trainee: {
    trainees: "trainee.trainees",
    traineesTasks: "trainee.traineesTasks",
    createTraineeTask: "trainee.createTraineeTask",
    position: "trainee.position",
    emptyDesc: "trainee.emptyDesc",
    taskAdded: "trainee.taskAdded"
  },
  access: {
    accessRemoved: "access.accessRemoved",
    searchUsers: "access.searchUsers",
    serachDepartment: "access.serachDepartment",
    searchPosition: "access.searchPosition",
    deleteAll: "access.deleteAll",

  },
  assign: {
    assignRemoved: "assign.assignRemoved",
    deleteAll: "assign.deleteAll",
  }
};
