import { FC, useMemo, useState } from "react";
import { Box, Button, Popover, Stack, SvgIcon, TextField, Typography, useTheme } from "@mui/material";
import { convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { getStyles } from "./styles";
import { useTranslation } from "react-i18next";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


import React from 'react';
import { Modifier } from 'draft-js';
import TocIcon from '@mui/icons-material/Toc';
import draftToHtml from "draftjs-to-html";

interface IProps {
    editorState: EditorState;
    placeholder?: string;
    disabled?: boolean;
    onEditorStateChange: (newEditorState: EditorState) => void;
    isToggleTable?: boolean;
};

const TextEditor: FC<IProps> = ({ editorState, disabled, placeholder, onEditorStateChange, isToggleTable }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDark = theme.palette.mode === 'dark';
    const styles = useMemo(() => getStyles(isDark), [isDark]);

    const uploadCallback = (file: any, callback: any) => {
        return new Promise((resolve, reject) => {
            const reader = new window.FileReader();
            reader.onloadend = async () => {
                const form_data = new FormData();
                form_data.append("files", file);
                const res = await uploadFile(form_data);

                resolve({ data: { link: res[0].url } });
            };
            reader.readAsDataURL(file);
        });
    };

    const uploadFile = async (formData: FormData) => {
        try {
            const accessToken = globalThis.localStorage.getItem("accessToken");
            const response = await fetch('https://lms.pizzaday.ua/api/file/add-files/', {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: "Bearer " + accessToken,
                }
            });

            if (!response.ok) {
                throw new Error('File upload failed');
            };

            return response.json();
        } catch (error) {
            console.error('Error uploading file:', error);
            throw error;
        }
    };

    const config = {
        image: {
            uploadCallback: uploadCallback,
            alt: { present: true, mandatory: false },
            previewImage: true,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg'
        },
        options: [
            'inline', 'fontSize', 'fontFamily', 'image', 'link', 'colorPicker', 'list', 'textAlign'
        ],
        inline: { inDropdown: true },
        list: { inDropdown: true },
        link: { inDropdown: true },
    };

    return (
        <Box sx={styles.editorContainer}>
            <Editor
                readOnly={disabled}
                placeholder={placeholder || ''}
                toolbar={config}
                editorState={editorState}
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
                handlePastedText={() => false}
                editorStyle={styles.editor as React.CSSProperties}
                toolbarStyle={styles.toolbar as React.CSSProperties}
                toolbarClassName="rdw-option-active"
                toolbarCustomButtons={!isToggleTable ? [<CustomOption editorState={editorState} onChange={onEditorStateChange} />] : []}
            />
        </Box>
    );
};

export default TextEditor;

interface IPropsOption {
    editorState: EditorState;
    onChange: (editorState: EditorState) => void;
};

const CustomOption: FC<IPropsOption> = ({ editorState, onChange }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [rows, setRows] = useState(1);
    const [cols, setCols] = useState(1);

    const openPopover = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closePopover = () => {
        setAnchorEl(null);
    };

    const generateTableHtml = (rows: number, columns: number, existingHtml: string) => {
        let table = '';
        table += '<table>\n';
        table += 'Заголовок: Назва\n';
        for (let i = 0; i < rows; i++) {
            let rowHtml = 'Рядок:';

            for (let j = 0; j < columns; j++) {
                rowHtml += ` Колонка-${j + 1} |`;
            };
            rowHtml = rowHtml.slice(0, -1);
            rowHtml += '\n';
            table += rowHtml;
        };
        table += '</table>';

        return table;
    };

    const handleInsertTable = () => {
        closePopover();
        setTimeout(() => {
            const contentState = editorState.getCurrentContent();
            const htmlContent = draftToHtml(convertToRaw(contentState));
            const table = generateTableHtml(rows, cols, htmlContent || '');

            const selectionState = editorState.getSelection();
            const newContentState = Modifier.insertText(contentState, selectionState, table);
            const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');

            onChange(newEditorState);
        }, 300);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Stack onClick={openPopover} sx={{ width: '36px', height: '31px', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }}>
                <SvgIcon><TocIcon /></SvgIcon>
            </Stack>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={closePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    width: '200px',
                }}
            >
                <Box sx={{ p: 2 }}>
                    <Typography variant="h6">Insert Table</Typography>
                    <form>
                        <TextField
                            label="Рядки"
                            type="number"
                            value={rows}
                            onChange={(e) => setRows(Number(e.target.value))}
                            InputProps={{ inputProps: { min: 1 } }}
                            size="small"
                            sx={{
                                '& .MuiInputBase-input': {
                                    height: '1.1rem',
                                },
                                marginTop: 1,
                            }}
                        />
                        <TextField
                            label="Колонки"
                            type="number"
                            value={cols}
                            onChange={(e) => setCols(Number(e.target.value))}
                            InputProps={{ inputProps: { min: 1 } }}
                            size="small"
                            sx={{
                                '& .MuiInputBase-input': {
                                    height: '1.1rem',
                                },
                                marginTop: 1,
                            }}
                        />
                    </form>
                    <Stack direction="row" alignItems="center" mt={1} spacing={1}>
                        <Button variant="contained" color="primary" size="small" onClick={handleInsertTable} sx={{ fontSize: '10px' }}>
                            Create
                        </Button>
                        <Button onClick={closePopover} variant="outlined" size="small" sx={{ fontSize: '10px' }}>
                            Close
                        </Button>
                    </Stack>
                </Box>
            </Popover>
        </>
    );
};