import { SelectChangeEvent, Typography } from "@mui/material";
import { ChangeEvent, FC, ReactNode } from "react";
import { UserRole } from "../userRole";
import { tokens } from "../../../../../../locales/tokens";
import { useTranslation } from "react-i18next";
import { UserPassword } from "../userPassword";

interface IProps {
    password: string;
    userRoles: string[];
    rolesList: any[];
    onBlurPassword: () => Promise<void>;
    onChangePassword: (event: ChangeEvent<HTMLInputElement>) => void;
    handleChangeRole: (event: SelectChangeEvent<string[]>, child: ReactNode) => void;
};

export const UserSettings: FC<IProps> = ({ userRoles, handleChangeRole, rolesList, password, onBlurPassword, onChangePassword }) => {
    const { t } = useTranslation();

    return (
        <>
            <Typography variant="h6" fontWeight={600}>{t(tokens.users.settings)}</Typography>
            <UserRole
                userRoles={userRoles}
                rolesList={rolesList}
                handleChangeRole={handleChangeRole}
            />
            <UserPassword
                value={password}
                onBlurValue={onBlurPassword}
                onChangeValue={onChangePassword}
            />
        </>

    )
};