import { ChangeEvent, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { tokens } from "../../../../../locales/tokens";
import { SelectChangeEvent } from "@mui/material";
import { selectOrgStructure } from "../../../../../store/orgStructure/repository/selector";
import { updateOrgStructure } from "../../../../../store/orgStructure/useCases/updateOrgStructure/action";
import { paths } from "../../../../../paths";
import { useNavigate } from "react-router-dom";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import toast from "react-hot-toast";
import draftToHtml from "draftjs-to-html";
import { createSingleDocument } from "../../../../../store/document/useCases/createDocument/action";
import { selectDocument } from "../../../../../store/document/repository/selector";
import { IError } from "../../../../../types/error";
import { getPositions } from "../../../../../store/user/useCases/getPositions/action";
import { selectUser } from "../../../../../store/user/repository/selector";
import { transformTable } from "../../../../../utils/transformToggleTable";

export const useDocumentCreate = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [type, setType] = useState('Page');
    const [typeValid, setTypeValid] = useState(true);
    const documentTypeOptions = [
        { label: t(tokens.documents.page), value: "Page" },
        { label: t(tokens.documents.file), value: "File" },
        { label: t(tokens.documents.video), value: "Video" },
        { label: t(tokens.documents.link), value: "Link" },
        { label: t(tokens.documents.gallery), value: "Gallery" },
    ];
    const [name, setName] = useState('');
    const [nameValid, setNameValid] = useState(true);
    const [category, setCategory] = useState<any>(null);
    const [categoryValid, setCategoryValid] = useState(true);
    const { orgStructure } = useSelector(selectOrgStructure);
    const [initiator, setInitiator] = useState<any>(null);
    const [initiatorValid, setInitiatorValid] = useState(true);
    const [groupPermissions, setGroupPermissions] = useState<any[]>([]);
    const [receiverValid, setReceiverValid] = useState(true);
    const [receiver, setReceiver] = useState<any[]>([]);
    const [publishChecked, setPublishChecked] = useState(false);
    const [notifyChecked, setNotifyChecked] = useState(false);
    const [initialContent, setInitialContent] = useState('{"blocks":[],"entityMap":{}}');
    const [editorState, setEditorState] = useState(() => {
        if (initialContent) {
            try {
                const parsedContent = JSON.parse(initialContent);
                const contentState = convertFromRaw(parsedContent);
                return EditorState.createWithContent(contentState);
            } catch (error) {
                console.error('Error parsing initial content:', error);
            }
        }
        return EditorState.createEmpty();
    });
    const [contentValid, setContentValid] = useState(true);
    const [fileType, setFileType] = useState('File');
    const fileTypeOptions = [
        { label: "Завантажити з компьютера", value: "File" },
        { label: "Посилання", value: "Link" },
    ];
    const [files, setFiles] = useState<any[]>([]);
    const [fileValid, setFileValid] = useState(true);
    const [textVideo, setTextVideo] = useState('');
    const [link, setLink] = useState('');
    const [linkValid, setLinkValid] = useState(true);
    const [createClick, setCreateClick] = useState(false);
    const { isLoading, createDocumentError } = useSelector(selectDocument);
    const { positionsList } = useSelector(selectUser);
    const [isOpenImageFile, setOpenImageFile] = useState(false);
    const [isOpenVideoFile, setOpenVideoFile] = useState(false);
    const [departmentPermissions, setDepartmentPermissions] = useState<any[]>([]);
    const [positions, setPositions] = useState<any[]>([]);
    const [assignDepartment, setAssignDepartment] = useState<any[]>([]);
    const [assignPositions, setAssignPositions] = useState<any[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        dispatch(updateOrgStructure());
        dispatch(getPositions());
    }, []);

    useEffect(() => {
        if (type) {
            setFileType(type === 'Link' ? 'Link' : 'File');
            setEditorState(EditorState.createEmpty());
            setFiles([]);
            setTextVideo('');
        }
    }, [type]);

    useEffect(() => {
        setFiles([]);
        setLink('');
    }, [fileType]);

    useEffect(() => {
        if (!isLoading && createClick) {
            if (createDocumentError) {
                handleServerError(createDocumentError);
            } else {
                toast.success(t(tokens.documents.messages.documentCreated));
                navigate(paths.dashboard.content.document.list);
            }
            setCreateClick(false);
        }
    }, [createDocumentError, isLoading]);

    const handleServerError = (error: IError) => {
        if (error?.startsWith("value too long for type character varying(255)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
            setNameValid(false);
        } else if (error?.startsWith("value too long for type character varying(100000)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
            setContentValid(false);
        } else {
            toast.error(error);
        }
    };

    const changeType = useCallback((event: SelectChangeEvent<any>) => {
        setType(event.target.value);
        setTypeValid(true);
    }, [setType]);

    const onChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        setNameValid(true);
    };

    const onSelectInitiator = (selectedKeysValue: string, node: any) => {                
        if ('children' in node) {
            return;
        }
        setInitiator(node);
        setInitiatorValid(true);
    };

    const onChangeInitiator = () => {
        if (initiator) {
            setInitiator(null);
        }
    };

    const onChangePermissions = (newValue: string[]) => {
        setGroupPermissions(newValue);
    };

    const onChangeDepartmentPermissions = (newValue: string[]) => {
        setDepartmentPermissions(newValue);
    };

    const onChangeAssignDepartment = (newValue: string[]) => {
        setAssignDepartment(newValue);
    };

    const onChangeReceiver = (newValue: string[]) => {
        setReceiver(newValue);
        setReceiverValid(true);
    };

    const handlePublishChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPublishChecked(event.target.checked);
    };

    const handleNotifyChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNotifyChecked(event.target.checked);
    };

    const onCategoryChange = (value: any) => {
        setCategory(value);
        setCategoryValid(true);
    };

    const onEditorStateChange = (newEditorState: EditorState) => {
        setEditorState(newEditorState);
        setContentValid(true);
    };

    const handleFilesDrop = useCallback((newFiles: File[]): void => {
        // const appropriateFiles = newFiles.filter((file: any) => file.size <= FILE_SIZE["1MB"]);
        setFiles((prevFiles) => {
            return [...prevFiles, ...newFiles];
        });
        setFileValid(true);
        // appropriateFiles.length !== newFiles.length && toast.error(t(tokens.documents.maxSizeFile));
    }, []);

    const handleFilesRemoveAll = useCallback((): void => {
        setFiles([]);
    }, []);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        // const maxSize = file?.type.startsWith('video') ? FILE_SIZE["20MB"] : FILE_SIZE["1MB"];
        if (file /* && file.size <= maxSize */) {
            setFiles([file]);
            setFileValid(true);
        } /* else {
            file?.type.startsWith('video') ?
                toast.error(t(tokens.documents.maxSizeFileVideo))
                : toast.error(t(tokens.documents.maxSizeFile));
        } */
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        };
    };

    const handleDeleteImage = (name: string) => {
        setFiles(prevFiles => prevFiles.filter(file => file.name !== name));
    };

    const changeFileType = useCallback((event: SelectChangeEvent<any>) => {
        setFileType(event.target.value)
    }, [setFileType]);

    const onChangeTextVideo = (event: ChangeEvent<HTMLInputElement>) => {
        setTextVideo(event.target.value);
    };

    const onChangeLink = (event: ChangeEvent<HTMLInputElement>) => {
        setLink(event.target.value);
        setLinkValid(true);
    };

    const handleToggleImageFile = () => {
        setOpenImageFile(!isOpenImageFile);
    };

    const handleToggleVideoFile = () => {
        if (type === 'Video') {
            setOpenVideoFile(!isOpenVideoFile);
        }
    };

    const validateData = () => {
        const data = [
            { field: type, setField: setTypeValid },
            { field: name, setField: setNameValid },
            { field: initiator, setField: setInitiatorValid },
            // { field: receiver.length, setField: setReceiverValid },
            { field: category, setField: setCategoryValid },
        ];
        if (type === 'Page') {
            const html = getHtml();
            const content = html.length !== 8;
            data.push({ field: content, setField: setContentValid });
        }
        if (type === 'File' || type === 'Gallery') {
            data.push({ field: files.length, setField: setFileValid });
        };
        if (type === 'Video') {
            if (fileType === 'File') data.push({ field: files.length, setField: setFileValid });
            if (fileType === 'Link') data.push({ field: link, setField: setLinkValid });
        };
        if (type === 'Link') {
            data.push({ field: link, setField: setLinkValid });
        };

        let allFieldsValid = true;
        for (const { field, setField } of data) {
            if (!field) {
                setField(false);
                allFieldsValid = false;
            }
        };

        return allFieldsValid;
    };

    const cleanedGuiIds = (guiIds: string[]): string[] => {
        return guiIds.map(permission => {
            const [cleaned] = permission.split('%');
            return cleaned;
        });
    };    

    const getRequestBody = () => {
        const body: any = { name, type };
        body.status = publishChecked ? "Available" : "Hidden";
        if (textVideo) body.description = textVideo;
        if (notifyChecked) body.isInformed = true;
        if (files.length) body.files = files;
        if (link) body.url = link;
        if (initiator) body.documentInitiatorId = initiator.value;
        if (receiver.length) body.access = receiver;
        if (groupPermissions.length) body.assigns = groupPermissions;
        const html = getHtml();
        if (html.length > 8) body.content = html;
        if (departmentPermissions.length) body.accessDepartmentIds = cleanedGuiIds(departmentPermissions);
        if (positions.length) body.accessPositionIds = positions.map(item => item.id);
        if (assignDepartment.length) body.assignDepartmentIds = cleanedGuiIds(assignDepartment);
        if (assignPositions.length) body.assignPositionIds = assignPositions.map(item => item.id);
        return body;
    };

    const getHtml = useCallback(() => {
        const contentState = editorState.getCurrentContent();
        const htmlContent = draftToHtml(convertToRaw(contentState));
    
        const updatedHtml = htmlContent.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;/g, '');
        const updatedContent = transformTable(updatedHtml);
    
        // console.log("htmlContent----->", htmlContent);
        // console.log("updatedHtml----->", updatedHtml);
        // console.log("updatedContent----->", updatedContent);
    
        return updatedContent;
    }, [editorState]);

    const createDocument = () => {
        const isPassedValidation = validateData();
        if (isPassedValidation) {
            dispatch(createSingleDocument({ body: getRequestBody(), category }));
            setCreateClick(true);
        } else {
            toast.error(t(tokens.adverts.fillAllFields))
        };
    };

    const cancelCreate = () => {
        navigate(paths.dashboard.content.document.list);
    };

    const handleChangePosition = (event: any, newValue: readonly any[]) => {
        setPositions([...newValue]);
    };

    const handleChangeAssignPosition = (event: any, newValue: readonly any[]) => {
        setAssignPositions([...newValue]);
    };

    return {
        type, typeValid, documentTypeOptions, changeType, name, nameValid, onChangeName, orgStructure, initiator, initiatorValid, onSelectInitiator, onChangeInitiator,
        groupPermissions, receiverValid, onChangePermissions, receiver, onChangeReceiver, publishChecked, handlePublishChange, notifyChecked, handleNotifyChange,
        createDocument, cancelCreate, category, categoryValid, editorState, contentValid, setEditorState, onEditorStateChange, files, fileValid, setFiles, handleFilesDrop,
        handleFilesRemoveAll, handleFileChange, handleDeleteImage, fileType, fileTypeOptions, changeFileType, textVideo, onChangeTextVideo, link, linkValid, onChangeLink,
        onCategoryChange, createClick, isOpenImageFile, handleToggleImageFile, isOpenVideoFile, handleToggleVideoFile, departmentPermissions, onChangeDepartmentPermissions,
        handleChangePosition, positions, positionsList, assignDepartment, assignPositions, handleChangeAssignPosition, onChangeAssignDepartment, fileInputRef
    }
};