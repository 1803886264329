import { ChangeEvent, ReactNode, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { tokens } from "../../../../../locales/tokens";
import { SelectChangeEvent } from "@mui/material";
import { selectOrgStructure } from "../../../../../store/orgStructure/repository/selector";
import { updateOrgStructure } from "../../../../../store/orgStructure/useCases/updateOrgStructure/action";
import { paths } from "../../../../../paths";
import { useNavigate, useParams } from "react-router-dom";
import { ContentState, EditorState, convertFromRaw, convertToRaw } from "draft-js";
import toast from "react-hot-toast";
import draftToHtml from "draftjs-to-html";
import { selectDocument } from "../../../../../store/document/repository/selector";
import { getSingleDocument } from "../../../../../store/document/useCases/getSingleDocument/action";
import { setSingleDocument } from "../../../../../store/document/repository/slice";
import htmlToDraft from "html-to-draftjs";
import { updateDocument } from "../../../../../store/document/useCases/updateDocument/action";
import { addFiles } from "../../../../../store/document/useCases/addFiles/action";
// import { deleteFile } from "../../../../../store/file/useCases/deleteFile/action";
import { deleteDocumentFiles } from "../../../../../store/document/useCases/deleteFiles/action";
import { FILE_SIZE } from "../../../../../config";
import { IError } from "../../../../../types/error";
import { getPositions } from "../../../../../store/user/useCases/getPositions/action";
import { selectUser } from "../../../../../store/user/repository/selector";
import { ensureParagraphTag, extractTablesFromHtml, transformHtml, transformTable } from "../../../../../utils/transformToggleTable";

export const useDocumentEdit = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [type, setType] = useState('');
    const [typeValid, setTypeValid] = useState(true);
    const documentTypeOptions = [
        { label: t(tokens.documents.page), value: "Page" },
        { label: t(tokens.documents.file), value: "File" },
        { label: t(tokens.documents.video), value: "Video" },
        { label: t(tokens.documents.link), value: "Link" },
        { label: t(tokens.documents.gallery), value: "Gallery" },
    ];
    const [name, setName] = useState('');
    const [nameValid, setNameValid] = useState(true);
    const [category, setCategory] = useState<any>(null);
    const [categoryValid, setCategoryValid] = useState(true);
    const { orgStructure } = useSelector(selectOrgStructure);
    const [initiator, setInitiator] = useState<any>(null);
    const [initiatorValid, setInitiatorValid] = useState(true);
    const [groupPermissions, setGroupPermissions] = useState<any[]>([]);
    const [receiverValid, setReceiverValid] = useState(true);
    const [receiver, setReceiver] = useState<any[]>([]);
    const [publishChecked, setPublishChecked] = useState(true);
    const [notifyChecked, setNotifyChecked] = useState(false);
    const [initialContent, setInitialContent] = useState('{"blocks":[],"entityMap":{}}');
    const [editorState, setEditorState] = useState(() => {
        if (initialContent) {
            try {
                const parsedContent = JSON.parse(initialContent);
                const contentState = convertFromRaw(parsedContent);
                return EditorState.createWithContent(contentState);
            } catch (error) {
                console.error('Error parsing initial content:', error);
            }
        }
        return EditorState.createEmpty();
    });
    const [contentValid, setContentValid] = useState(true);
    const [fileType, setFileType] = useState('File');
    const fileTypeOptions = [
        { label: "Завантажити з компьютера", value: "File" },
        { label: "Посилання", value: "Link" },
    ];
    const [files, setFiles] = useState<any[]>([]);
    const [fileValid, setFileValid] = useState(true);
    const [textVideo, setTextVideo] = useState('');
    const [link, setLink] = useState('');
    const [linkValid, setLinkValid] = useState(true);
    const [editClick, setEditClick] = useState(false);
    const { isLoading, updateDocumentError, singleDocument, galleryFiles, addFileError, deleteFileError } = useSelector(selectDocument);
    const { positionsList } = useSelector(selectUser);
    const [isOpenImageFile, setOpenImageFile] = useState(false);
    const [isOpenVideoFile, setOpenVideoFile] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [deletedFiles, setDeletedFiles] = useState<any[]>([]);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [departmentPermissions, setDepartmentPermissions] = useState<any[]>([]);
    const [positions, setPositions] = useState<any[]>([]);
    const [assignDepartment, setAssignDepartment] = useState<any[]>([]);
    const [assignPositions, setAssignPositions] = useState<any[]>([]);
    
    const [fileLoading, setFileLoading] = useState<boolean>(false);
    const [deleteFleLoading, setDeleteFileLoading] = useState<boolean>(false);
        
    useEffect(() => {
        dispatch(updateOrgStructure());
        dispatch(getPositions());
    }, []);

    useEffect(() => {
        id && dispatch(getSingleDocument({ id: Number(id) }));
        return () => {
            dispatch(setSingleDocument(null));
        }
    }, [id]);

    useEffect(() => {
        if (type && !initialized) {
            setFileType(type === 'Link' ? 'Link' : 'File');
            setEditorState(EditorState.createEmpty());
            setFiles([]);
            setTextVideo('');
        } else {
            setInitialized(false);
        }
    }, [type]);

    useEffect(() => {
        if (!initialized) {
            setFiles([]);
            setLink('');
        }
    }, [fileType]);

    useEffect(() => {
        if (singleDocument) {
            setType(singleDocument.type);
            setFileType(singleDocument.type === 'Link' || (singleDocument.type === 'Video' && singleDocument.url) ?
                'Link' : 'File'
            );
            setName(singleDocument.name);
            setCategory(singleDocument.categories.length ?
                {
                    id: singleDocument.categories[0].id,
                    name: singleDocument.categories[0].name,
                    label: singleDocument.categories[0].name,
                    value: singleDocument.categories[0].name,
                } : null);
            setInitiator(singleDocument.initiator?.id);
            // setReceiver(singleDocument.assigns.map((assign: any) => assign.id));
            // setGroupPermissions(singleDocument.familiarizes.map((permission: any) => permission.id));
            setPublishChecked(singleDocument.status === 'Available' ? true : false);
            setNotifyChecked(singleDocument.isInformed ? true : false);
            setLink(singleDocument.url || '');
            if (singleDocument.content) {
                const updatedHtml = extractTablesFromHtml(singleDocument.content);
                let result = transformHtml(updatedHtml);
                result = ensureParagraphTag(result);

                // console.log("singleDocument.content --->", singleDocument.content);
                // console.log("result --->", result);
                const contentBlocks = htmlToDraft(result || '');
                const contentState = ContentState.createFromBlockArray(contentBlocks.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState);
            };
            if (singleDocument.description) {
                setTextVideo(singleDocument.description);
            };
            if (singleDocument.files.length) {
                setFiles(singleDocument.files);
            };
            if (singleDocument.deleted_at) {
                setIsDeleted(true);
            };
            setInitialized(true);
        }
    }, [singleDocument]);

    useEffect(() => {
        if (galleryFiles?.length) {
            setFiles(galleryFiles);
        }
    }, [galleryFiles]);

    useEffect(() => {
        if (!isLoading && editClick) {
            if (updateDocumentError) {
                handleServerError(updateDocumentError);
            } else {
                toast.success(t(tokens.documents.messages.documentUpdated));
                navigate(paths.dashboard.content.document.list);
            }
            setEditClick(false);
        }
    }, [updateDocumentError, isLoading]);

    useEffect(() => {
        if (!isLoading && deleteFleLoading) {
            if (deleteFileError) {
                toast.error('Неможливо видалити файл')
            } else {
                type !== 'Gallery' && setFiles([]);
                toast.success("Файл видалено");
            }
            setDeleteFileLoading(false);
        }
    }, [deleteFileError, isLoading]);

    useEffect(() => {
        if (!isLoading && fileLoading) {
            if (addFileError) {
                toast.error(addFileError);
                setFiles([]);
            } else {
                toast.success('Файл додано');
            }
            setFileLoading(false);
        }
    }, [addFileError, isLoading]);
    
    const handleServerError = (error: IError) => {
        if (error?.startsWith("value too long for type character varying(255)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
            setNameValid(false);
        } else if (error?.startsWith("value too long for type character varying(100000)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
            setContentValid(false);
        } else {
            toast.error(error);
        }
    };

    const changeType = useCallback((event: SelectChangeEvent<any>) => {
        setType(event.target.value);
        setTypeValid(true);
    }, [setType]);

    const onChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        setNameValid(true);
    };

    const onSelectInitiator = (selectedKeysValue: string, node: any) => {
        if ('children' in node) {
            return;
        }
        setInitiator(node);
        setInitiatorValid(true);
    };

    const onChangeInitiator = () => {
        if (initiator) {
            setInitiator(null);
        }
    };

    const onChangePermissions = (newValue: string[]) => {
        setGroupPermissions(newValue);
    };

    const onChangeDepartmentPermissions = (newValue: string[]) => {
        setDepartmentPermissions(newValue);
    };

    const onChangeAssignDepartment = (newValue: string[]) => {
        setAssignDepartment(newValue);
    };

    const onChangeReceiver = (newValue: string[]) => {
        setReceiver(newValue);
        setReceiverValid(true);
    };

    const handlePublishChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPublishChecked(event.target.checked);
    };

    const handleNotifyChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNotifyChecked(event.target.checked);
    };

    const onCategoryChange = (value: any) => {
        setCategory(value);
        setCategoryValid(true);
    };

    const onEditorStateChange = (newEditorState: EditorState) => {
        setEditorState(newEditorState);
        setContentValid(true);
    };

    const handleFilesDrop = useCallback((newFiles: File[]): void => {
        // const appropriateFiles = newFiles.filter((file: any) => file.size <= FILE_SIZE["1MB"]);
        dispatch(addFiles({ id: Number(id), files: newFiles }));
        setFileValid(true);
        // appropriateFiles.length !== newFiles.length && toast.error(t(tokens.documents.maxSizeFile));
    }, []);

    const handleFilesRemoveAll = useCallback((): void => {
        const filesForDeleting = files.filter(file => 'mimetype' in file);
        if (filesForDeleting.length > 0) setDeletedFiles(filesForDeleting);
        setFiles([]);
    }, [deletedFiles]);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFileLoading(true);
        const file = event.target.files?.[0];
        if (file) {
            setFiles([file]);
            setFileValid(true);
            dispatch(addFiles({ id: Number(id), files: [file] }));
        }
    };
    
    const handleDeleteImageFromGallery = (name: string) => {
        setDeleteFileLoading(true);
        dispatch(deleteDocumentFiles({ fileName: name }));
    };

    const handleDeleteImage = (name: string) => {
        setDeleteFileLoading(true);
        dispatch(deleteDocumentFiles({ fileName: name }));
    };

    const changeFileType = useCallback((event: SelectChangeEvent<any>) => {
        setFileType(event.target.value)
    }, [setFileType]);

    const onChangeTextVideo = (event: ChangeEvent<HTMLInputElement>) => {
        setTextVideo(event.target.value);
    };

    const onChangeLink = (event: ChangeEvent<HTMLInputElement>) => {
        setLink(event.target.value);
        setLinkValid(true);
    };

    const handleToggleImageFile = () => {
        setOpenImageFile(!isOpenImageFile);
    };

    const handleToggleVideoFile = () => {
        if (type === 'Video') {
            setOpenVideoFile(!isOpenVideoFile);
        }
    };

    const validateData = () => {
        const data = [
            { field: type, setField: setTypeValid },
            { field: name, setField: setNameValid },
            { field: initiator, setField: setInitiatorValid },
            // { field: receiver.length, setField: setReceiverValid },
            { field: category, setField: setCategoryValid },
        ];
        if (type === 'Page') {
            const html = getHtml();
            const content = html.length !== 8;
            data.push({ field: content, setField: setContentValid });
        }
        if (type === 'File' || type === 'Gallery') {
            data.push({ field: files.length, setField: setFileValid });
        };
        if (type === 'Video') {
            if (fileType === 'File') data.push({ field: files.length, setField: setFileValid });
            if (fileType === 'Link') data.push({ field: link, setField: setLinkValid });
        };
        if (type === 'Link') {
            data.push({ field: link, setField: setLinkValid });
        };

        let allFieldsValid = true;
        for (const { field, setField } of data) {
            if (!field) {
                setField(false);
                allFieldsValid = false;
            }
        };

        return allFieldsValid;
    };

    const getHtml = useCallback(() => {
        const contentState = editorState.getCurrentContent();
        const htmlContent = draftToHtml(convertToRaw(contentState));

        const updatedHtml = htmlContent.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;/g, '');
        const updatedContent = transformTable(updatedHtml);

        // console.log("updatedHtml----->", updatedHtml);
        // console.log("updatedContent----->", updatedContent);

        return updatedContent;
    }, [editorState]);

    const cleanedGuiIds = (guiIds: string[]): string[] => {
        return guiIds.map(permission => {
            const [cleaned] = permission.split('%');
            return cleaned;
        });
    };

    const getRequestBody = () => {
        const body: any = { id: Number(id), name, type };
        body.status = publishChecked ? "Available" : "Hidden";
        if (textVideo) body.description = textVideo;
        body.isInformed = notifyChecked;
        if (link) body.url = link;
        if (initiator) body.initiatorId = initiator.value;
        body.access = receiver.length ? receiver : null;
        body.assigns = groupPermissions.length ? groupPermissions : null;
        const html = getHtml();
        if (html.length > 8) body.content = html;
        if (departmentPermissions.length) body.accessDepartmentIds = cleanedGuiIds(departmentPermissions);
        if (positions.length) body.accessPositionIds = positions.map(item => item.id);
        if (assignDepartment.length) body.assignDepartmentIds = cleanedGuiIds(assignDepartment);
        if (assignPositions.length) body.assignPositionIds = assignPositions.map(item => item.id);
        return body;
    };
    
    const editDocument = () => {
        const isPassedValidation = validateData();

        if (isPassedValidation) {
            // const newCategory = singleDocument.categories?.[0]?.id === category?.id ? null : category;
            dispatch(updateDocument({ body: getRequestBody(), category: category, files, deletedFiles }));
            setEditClick(true);
        } else {
            toast.error(t(tokens.adverts.fillAllFields))
        };
    };

    const cancelCreate = () => {
        navigate(paths.dashboard.content.document.list);
    };

    const handleChangePosition = (event: any, newValue: readonly any[]) => {
        setPositions([...newValue]);
    };

    const handleChangeAssignPosition = (event: any, newValue: readonly any[]) => {
        setAssignPositions([...newValue]);
    };

    return {
        type, typeValid, documentTypeOptions, changeType, name, nameValid, onChangeName, orgStructure, initiator, initiatorValid, onSelectInitiator, onChangeInitiator,
        groupPermissions, receiverValid, onChangePermissions, receiver, onChangeReceiver, publishChecked, handlePublishChange, notifyChecked, handleNotifyChange,
        editDocument, cancelCreate, category, categoryValid, editorState, contentValid, setEditorState, onEditorStateChange, files, fileValid, setFiles, handleFilesDrop,
        handleFilesRemoveAll, handleFileChange, handleDeleteImage, fileType, fileTypeOptions, changeFileType, textVideo, onChangeTextVideo, link, linkValid, onChangeLink,
        onCategoryChange, editClick, isOpenImageFile, isOpenVideoFile, handleToggleImageFile, handleToggleVideoFile, handleDeleteImageFromGallery, isDeleted,
        onChangeDepartmentPermissions, departmentPermissions, handleChangePosition, positions, positionsList, onChangeAssignDepartment, assignDepartment, handleChangeAssignPosition,
        assignPositions, id, fileLoading
    };
};