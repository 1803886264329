import { Stack, TextField, Typography } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";

interface IProps {
    value: string;
    onBlurValue: () => Promise<void>;
    onChangeValue: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const UserPassword: FC<IProps> = ({ value, onChangeValue, onBlurValue }) => {
    const { t } = useTranslation();

    return (
        <Stack direction='row' alignItems='center' width="500px" mt={2}>
            <Typography flex={1} variant="body1" width="200px">{t(tokens.auth.password)}</Typography>
            <TextField
                sx={{ flex: 2 }}
                fullWidth
                value={value}
                onChange={onChangeValue}
                onBlur={onBlurValue}
                label={t(tokens.auth.password)}
                autoComplete="off"
            />
        </Stack>
    )
};