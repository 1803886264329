export const getStyles = () => {
    const styles = {
        button: { 
            // borderRadius: '10px', 
            color: '#FFFFFF',
            fontWeight: 600,
            backgroundColor: '#F1594E',
            textTransform: 'none',
            '&:hover':{
                backgroundColor: '#d85046'
            } 
        },
    };
    return styles;
};