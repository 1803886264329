import React, { forwardRef, useEffect, useState } from 'react';
import { Box, CircularProgress, FormControl, IconButton, Stack, SvgIcon, Tooltip } from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';
import { getStyles } from './styles';

export const ImageUpload = forwardRef<HTMLInputElement, any>(({ setValue, clearErrors, image, isDisabled, isEdit }: any, ref) => {
    const { clearImage, progressContainer } = getStyles();
    const [file, setFile] = useState<any>(undefined);
    const [isImageLoading, setIsImageLoading] = useState(true);

    useEffect(() => {
        setFile(image ? image : undefined);
        setIsImageLoading(true); 
    }, [image]);

    const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        const file = e.target.files && e.target.files[0];
        if (file) {
            setFile(file);
            setValue('file', file);
            clearErrors('file');
        }
    };

    const handleClearImage = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setFile(undefined);
        setValue('file', undefined);
    };

    const handleImageLoad = () => {
        setIsImageLoading(false);
    };

    const getImageSrc = () => {
        if (file?.name) {
            return URL.createObjectURL(file);
        }
        if (file?.filename) {
            return file.url;
        }
        return "/images/user/uploadImage.png";
    };

    return (
        <Stack mt={1} position="relative" width="fit-content">
            <label className="image">
                {file && !isDisabled && !isEdit && !isImageLoading && (
                    <IconButton sx={clearImage} onClick={handleClearImage}>
                        <Tooltip title="Видалити" placement="right">
                            <SvgIcon><ClearIcon /></SvgIcon>
                        </Tooltip>
                    </IconButton>
                )}
            </label>
            <FormControl component="label" htmlFor="file">
                <>
                    {isImageLoading && (
                        <Box sx={progressContainer}>
                            <CircularProgress color="primary" size={50} />
                        </Box>
                    )}
                    <Box
                        component="img"
                        src={getImageSrc()}
                        alt="uploadImage"
                        width={112}
                        height={112}
                        className="image"
                        onLoad={handleImageLoad}
                        style={{ display: isImageLoading ? 'none' : 'block' }}
                    />
                    <Box
                        ref={ref}
                        disabled={isDisabled}
                        component="input"
                        type="file"
                        id="file"
                        name="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleChangeFile}
                    />
                </>
            </FormControl>
        </Stack>
    );
});