import { TableCell, TableRow, Typography } from "@mui/material";
import dayjs from "dayjs";
import { FC, useMemo } from "react";
import { getStyles } from "./styles";
import { tokens } from "../../../../../../locales/tokens";
import { useTranslation } from "react-i18next";
import { IHistory } from "../../../../../../store/learningHistory/repository/IHistory";

interface IProps {
    historyItem: IHistory;
};

interface ITaskStatus {
    Overdue: string;
    InProgress: string;
    Completed: string;
    finished: string;
    Need_familiarize: string;
    Assign: string;
    Failed: string;
};

export const HistoryTableItem: FC<IProps> = ({ historyItem }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const dateSet = historyItem.date_set ? dayjs(historyItem.date_set).format('DD.MM.YYYY') : "-";
    const dateFinish = historyItem.date_finish ? dayjs(historyItem.date_finish).format('DD.MM.YYYY') : "-";
    const dateDeadline = historyItem.date_deadline ? dayjs(historyItem.date_deadline).format('DD.MM.YYYY') : "-";
    const result = typeof historyItem.results === 'number' ? historyItem.results + "%" : "-";
    const TASK_STATUS: ITaskStatus | string = {
        Overdue: t(tokens.users.studyHistory.overdue),
        InProgress: t(tokens.users.studyHistory.inProgress),
        Completed: t(tokens.users.studyHistory.completed),
        finished: t(tokens.users.studyHistory.completed),
        Need_familiarize: t(tokens.users.studyHistory.assign),
        Assign: t(tokens.users.studyHistory.assign),
        Failed: t(tokens.users.studyHistory.failed)
    };
        
    return (
        <TableRow hover key={historyItem.id}>
            <TableCell align="left" sx={styles.cell}>
                <Typography sx={styles.name} variant="body2">{historyItem.name}</Typography>
            </TableCell>
            <TableCell align="center" sx={styles.cell}>
                <Typography variant="body2">{historyItem.type}</Typography>
            </TableCell>
            <TableCell align="center" sx={styles.cell}>
                <Typography variant="body2">{dateSet}</Typography>
            </TableCell>
            <TableCell align="center" sx={styles.cell}>
                <Typography variant="body2">{dateFinish}</Typography>
            </TableCell>
            <TableCell align="center" sx={styles.cell}>
                <Typography variant="body2">{dateDeadline}</Typography>
            </TableCell>
            <TableCell align="center" sx={styles.cell}>
                <Typography variant="body2">{TASK_STATUS?.[historyItem?.status] || TASK_STATUS.Assign }</Typography>
            </TableCell>
            <TableCell align="center" sx={styles.cell}>
                <Typography variant="body2">{result}</Typography>
            </TableCell>
        </TableRow>
    )
};