import { Divider, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { useUserEdit } from "../presenters/useUserEdit";
import { UserSettings } from "./components/userSettings";
import { UserInfo } from "./components/userInfo";
import { UserAvatar } from "./components/userAvatar";

export const UserEdit: FC = () => {
    const { t } = useTranslation();
    const { singleUser, userRoles, handleChangeRole, imageSrc, handleFileChange, roles, password, onBlurPassword, onChangePassword } = useUserEdit();

    return (
        <Stack m={2}>
            <Typography variant="h4" fontWeight={600}>{t(tokens.users.userDetail)}</Typography>
            <Stack direction='row' spacing={5} alignItems='center' mt={4}>
                <UserAvatar
                    imageSrc={imageSrc}
                    handleFileChange={handleFileChange}
                />
                <UserInfo user={singleUser} />
            </Stack>
            <Divider sx={{ my: 3 }} />
            <UserSettings
                password={password}
                userRoles={userRoles}
                rolesList={roles}
                onBlurPassword={onBlurPassword}
                onChangePassword={onChangePassword}
                handleChangeRole={handleChangeRole}
            />
        </Stack>
    )
};