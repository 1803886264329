import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { paths } from '../../../../paths';
import { useCreateBadgeMutation, useGetBadgeQuery, useUpdateBadgeMutation } from '../../../../store/badge/useCases';
import { selectOrgStructure } from '../../../../store/orgStructure/repository/selector';
import { getOrgStructure } from '../../../../store/orgStructure/useCases/getOrgStructure/action';

const upsertBadgeSchema = z.object({
    name: z.string().min(1, { message: "Not empty" }),
    points: z.union([z.number(), z.string()]).nullable().optional(),
    file: z.any().refine((file) => (file !== undefined), { message: "File is required" }).refine((file) => !file.size || file.size <= 1 * 1024 * 1024, { message: "File size should be less than or equal to 1 MB" }),
    description: z.string().nullable(),
    initiatorId: z.number().optional().refine(value => value !== undefined, { message: "Initiator ID is required" }),
    assigneeIds: z.array(z.number()).nullable()
});

export type upsertBadgeSchema = z.infer<typeof upsertBadgeSchema>;

export const useUpsertBadge = () => {
    const navigation = useNavigate();
    const dispatch = useDispatch();

    const { id: idBadge } = useParams();
    const [searchParams] = useSearchParams();

    const isDisabledField = searchParams.get('view') !== null;

    const { orgStructure } = useSelector(selectOrgStructure);

    const { data: { name = '', points = '', description = '', image, initiatorId = undefined, users = [] } = {}, isLoading: isLoadingGetBadge } = useGetBadgeQuery(Number(idBadge), { skip: !idBadge, refetchOnMountOrArgChange: true });

    const [createBadge, { isLoading: isLoadingCreateBadge, isSuccess: isSuccessCreateBadge }] = useCreateBadgeMutation();
    const [updateBadge, { isLoading: isLoadingUpdateBadge, isSuccess: isSuccessUpdateBadge }] = useUpdateBadgeMutation();

    const { control, handleSubmit, setValue, clearErrors, watch, formState: { errors }, reset } = useForm<upsertBadgeSchema>({
        resolver: zodResolver(upsertBadgeSchema),
        defaultValues: { name: '', points: '', description: '', file: undefined },
        //@ts-ignore
        values: { name, points: points ? points : '', description: description ? description : '', initiatorId, assigneeIds: [] }
    });

    useEffect(() => {
        dispatch(getOrgStructure());
    }, []);

    useEffect(() => {
        if (isSuccessCreateBadge || isSuccessUpdateBadge) {
            toast.success(isSuccessCreateBadge ? "Бейдж успішно створено" : "Бейдж успішно оновлено");
            navigation('/badges');
        }
    }, [isSuccessCreateBadge, isSuccessUpdateBadge]);

    useEffect(() => {
        if (!idBadge) {
            reset({ name: '', points: '', file: undefined, description: '', initiatorId: undefined, assigneeIds: [] });
        }
    }, [idBadge]);

    useEffect(() => {        
        if (Object.keys(errors).length !== 0) {
            toast.error(errors?.file?.message === 'File is required'
                ? "Заповніть всі обов'язкові поля"
                : errors?.file?.message === 'File size should be less than or equal to 1 MB' && (errors?.name?.message || errors?.initiatorId?.message)
                    ? "Заповніть всі обов'язкові поля, максимальний розмір файлу - 1 Мб"
                    : errors?.file?.message === 'File size should be less than or equal to 1 MB'
                        ? "Максимальний розмір файлу - 1 Мб"
                        : "Заповніть всі обов'язкові поля");
        };
    }, [errors]);

    useEffect(() => {
        if(image?.url){
            clearErrors('file');
            setValue('file', image);
        }
    }, [image?.url]);

    const handleUpsertBadge: SubmitHandler<upsertBadgeSchema> = (data) => {
        const { initiatorId, assigneeIds, description, ...payloadUpdate } = data;
        idBadge ? updateBadge({ ...payloadUpdate, id: +idBadge, description, initiatorId, assigneeIds }) : createBadge(data);
    };

    const handleBadgeNavigation = () => navigation(paths.dashboard.badge.index);

    return {
        idBadge, isDisabledField, isLoadingGetBadge, handleSubmit, handleUpsertBadge, control, errors, setValue, clearErrors, watch, orgStructure,
        isLoadingCreateBadge, isLoadingUpdateBadge, handleBadgeNavigation
    };
};
