import { FC, useMemo } from "react";
import { useEditAssign } from "../presenter/useEditAssign";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { AssignSearch } from "./components/assignSearch";
import { AssignTable } from "./components/assignTable";
import { EmptyPage } from "../../../modules/content/category/categoryEmpty/ui";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";
import { getStyles } from "./styles";

interface IProps {
    type: string;
};

export const EditAssignDocument: FC<IProps> = ({ type }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { filters, searchTextRef, handleSortBy, handleChangeSearch, handleSearchTextChange, handleDelete, pageAmount, handlePageChange,
        scrollRef, assignList, searchTitle, isProccess, deleteAllAssign } = useEditAssign(type);

    return (
        <Stack mt={2} ml={2}>
            <Typography variant="h5" fontWeight={600}>Редагувати призначення</Typography>
            <AssignSearch
                searchTitle={searchTitle}
                searchLabel="Призначення"
                sortBy={filters.sortBy}
                isButton={!!assignList.length}
                searchTextRef={searchTextRef}
                handleSortBy={handleSortBy}
                deleteAll={deleteAllAssign}
                handleChangeSearch={handleChangeSearch}
                handleSearchTextChange={handleSearchTextChange}
            />
            {!!assignList.length &&
                <AssignTable
                    sortedBy={filters.sortBy}
                    scrollRef={scrollRef}
                    assignList={assignList}
                    page={filters.page}
                    pageAmount={pageAmount}
                    handleDelete={handleDelete}
                    handlePageChange={handlePageChange}
                />}
            {(!assignList.length && isProccess) &&
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={styles.progress}
                >
                    <CircularProgress color="primary" />
                </Stack>
            }
            {(!assignList.length && !isProccess) &&
                <Stack direction="row" alignItems="flex-start" justifyContent="center">
                    <EmptyPage
                        title={t(tokens.common.emptyState)}
                        description={t(tokens.common.emptyStateAssign)}
                    />
                </Stack>
            }
        </Stack>
    )
};